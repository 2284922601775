import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import Masonry from '../components/Masonry'

function Page({ location }) {
  const { shareImage } = useStaticQuery(graphql`
    query {
      shareImage: file(
        relativePath: { eq: "services/patio-decking-feature-5.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <>
      <Meta
        title="Patio & Decking"
        description="We offer a wide range in choice of materials and will talk to you about your options and help you choose something that will suit your budget and lifestyle."
        location={location}
        image={shareImage}
      />
      <Layout>
        <section className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray">
                Patio &amp; Decking
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mt-8 lg:mt-0">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-light">
                    We offer a full range of hard landscaping services. Our work
                    is built to last and is given with a guarantee. We offer a
                    wide range in choice of materials and will talk to you about
                    your options and help you choose something that will suit
                    your design style, budget, and lifestyle.
                  </p>
                </div>
                <div className="mt-5 prose prose-indigo text-gray-light mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <p>
                    All of our paving works are given a suitable and fully
                    compacted foundation to ensure longevity of our works. We
                    also implement our works with drainage in mind. We provide
                    the levels for water run-off and always provide extra
                    drainage or soakaways where needed.
                  </p>
                  <p>
                    Please call for a free quotation for your hard landscaping
                    projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Masonry>
          <StaticImage
            src="../images/services/patio-decking-feature-3.jpg"
            alt="Featured work"
            aspectRatio={4 / 3}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-4.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-5.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-6.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-7.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-8.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-9.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-10.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-11.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-12.jpg"
            alt="Featured work"
            aspectRatio={3 / 4}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/patio-decking-feature-13.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
        </Masonry>
      </Layout>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Page
